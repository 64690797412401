import React from "react";

import clsx from "clsx";
import { DROP_SVG } from "../../../constants/svgConstants";

import { useTranslation } from "next-i18next";
import { useToggle } from "../../../hooks/useToggle";

const OfferTextBlock = ({ seoText, isFull }) => {
  const [t] = useTranslation();

  const [showMore, setShowMore] = useToggle(isFull);

  return (
    <>
      {seoText && (
        <div className="landing-main-block">
          <div className="container">
            <div
              className={clsx({
                "seo-text-wrapper__hidden": !showMore,
              })}
            >
              {/* seo-text-wrapper */}
              <div
                className="seo-text-block description-html"
                dangerouslySetInnerHTML={{ __html: seoText }}
              ></div>
            </div>

            {!isFull && (
              <button
                onClick={setShowMore}
                className={
                  showMore
                    ? "seo-text-button seo-text-button_less"
                    : "seo-text-button seo-text-button_more"
                }
              >
                {t(showMore ? "hide" : "more")} {DROP_SVG}
              </button>
            )}
          </div>
          <style jsx global>{`
            @import "./SeoTextBlock.scss?";
          `}</style>
        </div>
      )}
    </>
  );
};

export default OfferTextBlock;
