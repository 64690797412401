import React from "react";
import OfferTextBlock from "../../../../common/SeoTextBlock/OfferTextBlock";
import { useTranslation } from "next-i18next";
import { useQuery } from "@apollo/client";
import { useRouter } from "next/router";
import { GET_CLIENT_EVENT_OFFER } from "../../../../../graphql/queries/queriesEvent";
import { GET_CLUB_OFFER, GET_HALL_OFFER } from "../../../../../graphql/queries/queriesClub";
import { GET_CARD_OFFER, GET_CARD_TEMPLATE_OFFER } from "../../../../../graphql/queries/queriesCard";

function OfferPage() {
  const [t] = useTranslation();

  const router = useRouter();
  const {
    query: { club, templateId, cardId, eventId, hallId },
  } = router;

  const id = eventId ? eventId : cardId ? cardId : templateId ? templateId : hallId;

  const { data: { offerData: { offer = "" } = {} } = {} } = useQuery(
    eventId
      ? GET_CLIENT_EVENT_OFFER
      : cardId
        ? GET_CARD_OFFER
        : templateId
          ? GET_CARD_TEMPLATE_OFFER
          : hallId
            ? GET_HALL_OFFER
            : GET_CLUB_OFFER,
    {
      fetchPolicy: "network-only",
      variables: { id: +id },
      // skip: !id,
      onError: (e) => console.log(e),
    }
  );

  // useEffect(() => {
  //   if (!id) router.replace(`/club/${club}`);
  // }, [id]);

  return (
    <>
      <div className="club-offer-page">
        <div className="club-offer-page__container container">
          <h1 className="club-offer-page__title">{t("the_contract")}</h1>

          <OfferTextBlock seoText={offer} isFull />
        </div>
      </div>
      <style jsx global>{`
        @import "../../../LandingPage/Landing.scss?";
        @import "./OfferPage.scss";
      `}</style>
    </>
  );
}

export default OfferPage;
